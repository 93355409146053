import { ReportHandler } from 'web-vitals';

// Performance metrics thresholds (in milliseconds)
const PERFORMANCE_THRESHOLDS = {
    FCP: 2000,  // First Contentful Paint
    LCP: 2500,  // Largest Contentful Paint
    FID: 100,   // First Input Delay
    CLS: 0.1,   // Cumulative Layout Shift
    TTFB: 600   // Time to First Byte
};

interface PerformanceMetric {
    name: string;
    value: number;
    rating: 'good' | 'needs-improvement' | 'poor';
    delta: number;
    id: string;
    entries: PerformanceEntry[];
}

class PerformanceMonitor {
    private static instance: PerformanceMonitor;
    private metricsBuffer: PerformanceMetric[] = [];
    private isMonitoring = false;

    private constructor() {
        // Private constructor for singleton pattern
    }

    public static getInstance(): PerformanceMonitor {
        if (!PerformanceMonitor.instance) {
            PerformanceMonitor.instance = new PerformanceMonitor();
        }
        return PerformanceMonitor.instance;
    }

    public startMonitoring(): void {
        if (this.isMonitoring) return;
        this.isMonitoring = true;

        // Initialize Performance Observer for custom metrics
        if ('PerformanceObserver' in window) {
            this.observeNavigationTiming();
            this.observeResourceTiming();
            this.observeLayoutShift();
            this.observePaintTiming();
        }

        // Initialize web-vitals monitoring
        this.initWebVitals();
    }

    private observeNavigationTiming(): void {
        const navigationObserver = new PerformanceObserver((entryList) => {
            const entries = entryList.getEntries();
            entries.forEach(entry => {
                if (entry.entryType === 'navigation') {
                    const navEntry = entry as PerformanceNavigationTiming;
                    this.logNavigationMetrics(navEntry);
                }
            });
        });

        navigationObserver.observe({ entryTypes: ['navigation'] });
    }

    private observeResourceTiming(): void {
        const resourceObserver = new PerformanceObserver((entryList) => {
            const entries = entryList.getEntries();
            entries.forEach(entry => {
                if (entry.entryType === 'resource') {
                    const resourceEntry = entry as PerformanceResourceTiming;
                    this.logResourceMetrics(resourceEntry);
                }
            });
        });

        resourceObserver.observe({ entryTypes: ['resource'] });
    }

    private observeLayoutShift(): void {
        const layoutShiftObserver = new PerformanceObserver((entryList) => {
            const entries = entryList.getEntries();
            entries.forEach(entry => {
                if (entry.entryType === 'layout-shift') {
                    this.logLayoutShiftMetrics(entry);
                }
            });
        });

        layoutShiftObserver.observe({ entryTypes: ['layout-shift'] });
    }

    private observePaintTiming(): void {
        const paintObserver = new PerformanceObserver((entryList) => {
            const entries = entryList.getEntries();
            entries.forEach(entry => {
                if (entry.entryType === 'paint') {
                    this.logPaintMetrics(entry);
                }
            });
        });

        paintObserver.observe({ entryTypes: ['paint'] });
    }

    private initWebVitals(): void {
        const reportWebVitals: ReportHandler = (metric) => {
            const { name, value, rating, delta, id, entries } = metric;
            
            this.metricsBuffer.push({
                name,
                value,
                rating,
                delta,
                id,
                entries
            });

            // Report to analytics
            this.reportToAnalytics({
                metric: name,
                value: value,
                rating: rating
            });

            // Check against thresholds
            this.checkThresholds(name, value);
        };

        // Import and initialize web-vitals
        import('web-vitals').then(({ onCLS, onFID, onFCP, onLCP, onTTFB }) => {
            onCLS(reportWebVitals);
            onFID(reportWebVitals);
            onFCP(reportWebVitals);
            onLCP(reportWebVitals);
            onTTFB(reportWebVitals);
        });
    }

    private logNavigationMetrics(entry: PerformanceNavigationTiming): void {
        const metrics = {
            dnsLookup: entry.domainLookupEnd - entry.domainLookupStart,
            tcpConnection: entry.connectEnd - entry.connectStart,
            serverResponse: entry.responseStart - entry.requestStart,
            domLoad: entry.domContentLoadedEventEnd - entry.loadEventStart,
            pageLoad: entry.loadEventEnd - entry.loadEventStart
        };

        this.reportToAnalytics({
            metric: 'navigation_timing',
            value: metrics
        });
    }

    private logResourceMetrics(entry: PerformanceResourceTiming): void {
        const metrics = {
            name: entry.name,
            duration: entry.duration,
            transferSize: entry.transferSize,
            initiatorType: entry.initiatorType
        };

        this.reportToAnalytics({
            metric: 'resource_timing',
            value: metrics
        });
    }

    private logLayoutShiftMetrics(entry: any): void {
        this.reportToAnalytics({
            metric: 'layout_shift',
            value: entry.value
        });
    }

    private logPaintMetrics(entry: PerformanceEntry): void {
        this.reportToAnalytics({
            metric: 'paint_timing',
            value: {
                name: entry.name,
                duration: entry.duration
            }
        });
    }

    private checkThresholds(metricName: string, value: number): void {
        const threshold = PERFORMANCE_THRESHOLDS[metricName as keyof typeof PERFORMANCE_THRESHOLDS];
        if (threshold && value > threshold) {
            console.warn(`Performance metric ${metricName} (${value}) exceeds threshold (${threshold})`);
            
            // Report performance issue to monitoring service
            this.reportToAnalytics({
                metric: 'performance_threshold_exceeded',
                value: {
                    metric: metricName,
                    value: value,
                    threshold: threshold
                }
            });
        }
    }

    private reportToAnalytics(data: any): void {
        // Send to Google Analytics
        if (window.gtag) {
            window.gtag('event', 'performance_metric', {
                event_category: 'Performance',
                event_label: data.metric,
                value: typeof data.value === 'number' ? Math.round(data.value) : undefined,
                metric_value: data.value,
                metric_rating: data.rating,
                non_interaction: true
            });
        }

        // Log to console in development
        if (process.env.NODE_ENV === 'development') {
            console.log('Performance Metric:', data);
        }
    }

    public getMetricsBuffer(): PerformanceMetric[] {
        return [...this.metricsBuffer];
    }

    public clearMetricsBuffer(): void {
        this.metricsBuffer = [];
    }
}

export default PerformanceMonitor;
